<template>
    <div style="height:100%">
        <iframe style="width:100%;height:100%;" :src="URL"></iframe>
    </div>
</template>
<script>
import domainConfig from '@/config/domainConfig'
export default {
    data() {
        return {
            URL:domainConfig.taskTempUrl
        }
    },
    created() {
    },
    methods: {
        
    },
}
</script>